import { createSlice } from '@reduxjs/toolkit'

//same as ctasSlice
export const initialState = []

export const customScriptsSlice = createSlice({
	name: 'userData/customscripts',
	initialState,
	reducers: {
        setCustomScriptsData: (_, action) => action.payload,
        deleteCustomScriptsData: (state, action) => {
			const findIndex = state.findIndex(a => a.id === action.payload.id)
			state.splice(findIndex, 1)
        },
        setEmptyCustomScripts: () => initialState,
	},
});

export const { setCustomScriptsData, setEmptyCustomScripts, deleteCustomScriptsData } = customScriptsSlice.actions

export default customScriptsSlice.reducer;